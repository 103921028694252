import React, { createContext, useContext, useReducer } from 'react'

import { useTranslation } from 'next-i18next'

import { useLocalStorage } from '@dentalux/common'
import { Language } from '@dentalux/security'

import { LocalStorage } from 'src/@types/LocalStorage'

export enum ActionTypes {
  setEmail = 'setEmail',
  reset = 'setDefault',
  setBookingLink = 'setBookingLink',
  setDisableBiometricLogin = 'setDisableBiometricLogin',
  setPreferredLanguageCode = 'setPreferredLanguageCode',
  setOfferRememberDevice = 'setOfferRememberDevice',
}

type SetEmailAction = { type: ActionTypes.setEmail; payload: string }
type SetBookingLinkAction = { type: ActionTypes.setBookingLink; payload: string }
type SetPreferredLanguageCodeAction = { type: ActionTypes.setPreferredLanguageCode; payload: Language }
type SetOfferRememberDeviceAction = { type: ActionTypes.setOfferRememberDevice; payload: boolean }
type SetDisableBiometricLoginAction = { type: ActionTypes.setDisableBiometricLogin }
type ResetAction = { type: ActionTypes.reset }

type State = {
  email?: string
  bookingLink?: string
  preferredLanguageCode?: Language
  offerRememberDevice?: boolean
}

type DynamicState = { biometricLoginDisabled?: boolean }

type DerivedState = { shouldSyncLanguage: boolean }

type Dispatch = (
  action:
    | SetEmailAction
    | SetPreferredLanguageCodeAction
    | SetBookingLinkAction
    | SetDisableBiometricLoginAction
    | SetOfferRememberDeviceAction
    | ResetAction
) => void

const UnauthorizedContext = createContext<(State & DerivedState & DynamicState) | undefined>(undefined)

const UnauthorizedDispatchContext = createContext<Dispatch | undefined>(undefined)

export const defaultUnauthorizedState = {
  email: '',
  bookingLink: '',
  langChanged: undefined,
  offerRememberDevice: false,
  biometricLoginDisabled: false,
}

const unauthorizedReducer =
  (persist) =>
  (
    state: State,
    action:
      | SetEmailAction
      | SetBookingLinkAction
      | SetPreferredLanguageCodeAction
      | SetOfferRememberDeviceAction
      | SetDisableBiometricLoginAction
      | ResetAction
  ) => {
    const finalize = (nextState: State & DynamicState) => {
      const { biometricLoginDisabled, ...persistedState } = nextState

      persist(persistedState)
      return { biometricLoginDisabled, ...persistedState }
    }

    switch (action.type) {
      case ActionTypes.setDisableBiometricLogin: {
        return finalize({ ...state, biometricLoginDisabled: true })
      }

      case ActionTypes.setEmail:
        return finalize({ ...state, email: action.payload })

      case ActionTypes.setBookingLink:
        return finalize({ ...state, bookingLink: action.payload })

      case ActionTypes.setOfferRememberDevice:
        return finalize({ ...state, offerRememberDevice: action.payload })

      case ActionTypes.setPreferredLanguageCode:
        return finalize({ ...state, preferredLanguageCode: action.payload })

      case ActionTypes.reset:
        return finalize(defaultUnauthorizedState)

      default:
        throw new Error(`Unhandled action`)
    }
  }

export const UnauthorizedProvider = ({ children }) => {
  const [value, setValue] = useLocalStorage(LocalStorage.Unauthorized, defaultUnauthorizedState)

  const [state, dispatch] = useReducer(unauthorizedReducer(setValue), { ...value })

  const { i18n } = useTranslation()

  const derivedState: DerivedState = {
    shouldSyncLanguage: i18n.language === state.preferredLanguageCode,
  }

  return (
    <UnauthorizedContext.Provider value={{ ...state, ...derivedState }}>
      <UnauthorizedDispatchContext.Provider value={dispatch}>{children}</UnauthorizedDispatchContext.Provider>
    </UnauthorizedContext.Provider>
  )
}

export const useUnauthorizedContext = () => {
  const context = useContext(UnauthorizedContext)

  if (context === undefined) {
    throw new Error('useUnauthorizedContext must be used within a UnauthorizedContext')
  }

  return context
}

export const useUnauthorizedDispatch = () => {
  const context = useContext(UnauthorizedDispatchContext)

  if (context === undefined) {
    throw new Error('useUnauthorizedDispatch must be used within a UnauthorizedDispatchContext')
  }

  return context
}

export const setBookingLink = (payload: string): SetBookingLinkAction => ({
  type: ActionTypes.setBookingLink,
  payload,
})

export const setEmail = (payload: string): SetEmailAction => ({
  type: ActionTypes.setEmail,
  payload,
})

export const setPreferredLanguageCode = (payload: Language): SetPreferredLanguageCodeAction => ({
  type: ActionTypes.setPreferredLanguageCode,
  payload,
})

export const setOfferRememberDevice = (payload: boolean): SetOfferRememberDeviceAction => ({
  type: ActionTypes.setOfferRememberDevice,
  payload,
})

export const setDisableBiometricLogin = (): SetDisableBiometricLoginAction => ({
  type: ActionTypes.setDisableBiometricLogin,
})

export const reset = (): ResetAction => ({
  type: ActionTypes.reset,
})
