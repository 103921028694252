import { PainDetailsData } from '@dentalux/ui-library-core/cjs/custom/components/pain-details-form/types'

export enum BookingPurpose {
  Reschedule = 'RESCHEDULE',
  Booking = 'BOOKING',
}

export enum BookingCreationEvent {
  Recommendation = 'RECOMMENDATION',
  RecommendationGapfiller = 'RECOMMENDATION_GAPFILLER',
  CrmRecommendation = 'CRM_RECOMMENDATION',
  Upsell = 'UPSELL',
}

type PainInformationItem = {
  questionContentfulKey: string
  answerContentfulKey: string
  answerValue: string
}

export type AdditionalAppointmentHints = {
  painInformation?: Partial<{ [K in keyof PainDetailsData]: PainInformationItem }>
  upsellInformation?: typeof vitaminDUpsellInformation
}

export const vitaminDUpsellInformation = {
  vitaminD: {
    questionContentfulKey: 'upsell.vitamin_d.hint.question',
    answerContentfulKey: 'upsell.vitamin_d.hint.answer_yes',
    answerValue: null,
  },
}
